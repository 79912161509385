export * from './iconfont'
import * as utils from 'js/utils/index'

/**
 * svg 渐变样式生成器
 */
export function svgGradientGenerator () {
  if (typeof document.createElementNS === 'undefined') {
    utils.log.e(`[createElementNS] svg gradient generating failed. The metohd havn't been supported by this browser.`)
  }

  let linearArr = [
    // 红心
    {
      id: 'svg_color_heart_red',
      stopColor1: '#FF9774',
      stopColor2: '#FF0000',
    },
    // 蓝时钟
    {
      id: 'svg_color_clock_blue',
      stopColor1: '#8EDCFF',
      stopColor2: '#40ADFF',
    },
    // 蓝色小时钟
    {
      id: 'svg_color_clock1_blue',
      x1: '50',
      x2: '50%',
      y1: '0',
      y2: '100%',
      stopColor1: '5FC9FF',
      stopColor2: '#359EFF',
    },
    // 蓝火
    {
      id: 'svg_color_flame_blue',
      stopColor1: '#369FFF',
      stopColor2: '#B3DBFF',
    },
    // 红火
    {
      id: 'svg_color_flame_red',
      stopColor1: '#FF6C57',
      stopColor2: '#FFD69E',
    },
    // 黄火
    {
      id: 'svg_color_flame_yellow',
      stopColor1: '#FFB300',
      stopColor2: '#FFE4A8',
    }
  ] // 线性渐变参数

  let nameSpace = 'http://www.w3.org/2000/svg'
  let svgNode = document.createElementNS(nameSpace, 'svg')
  let defNode = document.createElementNS(nameSpace, 'defs')
  let fragment = document.createDocumentFragment()

  svgNode.setAttribute('aria-hidden', 'true')
  svgNode.style.height = '0'
  svgNode.style.width = '0'
  svgNode.style.overflow = 'hidden'

  for (let o of linearArr) {
    createLinear(o)
  }

  defNode.appendChild(fragment)
  svgNode.appendChild(defNode)
  document.querySelector('body').appendChild(svgNode)

  /**
   * 生成线性渐变标签
   * @param {String} x1
   * @param {String} x2
   * @param {String} y1
   * @param {String} y2
   * @param {String} id
   * @param {String} stopColor1
   * @param {String} stopColor2
   * @param {String} stopOffset1
   * @param {String} stopOffset2
   */
  function createLinear (options = {}) {
    let {
      x1 = '50%',
      x2 = '50%',
      y1 = '0',
      y2 = '100%',
      id = '',
      stopColor1 = '',
      stopColor2 = '',
      stopOffset1 = '0',
      stopOffset2 = '100%'
    } = options

    let lNode = document.createElementNS(nameSpace, 'linearGradient')
    let s1Node = document.createElementNS(nameSpace, 'stop')
    let s2Node = document.createElementNS(nameSpace, 'stop')

    lNode.setAttribute('x1', x1)
    lNode.setAttribute('x2', x2)
    lNode.setAttribute('y1', y1)
    lNode.setAttribute('y2', y2)
    lNode.setAttribute('id', id)
    s1Node.setAttribute('offset', stopOffset1)
    s1Node.setAttribute('stop-color', stopColor1)
    s2Node.setAttribute('offset', stopOffset2)
    s2Node.setAttribute('stop-color', stopColor2)
    lNode.appendChild(s1Node)
    lNode.appendChild(s2Node)
    fragment.appendChild(lNode)
  }
}