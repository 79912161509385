// babel-polyfill
export * from 'babel-polyfill'
// jQuery
import $ from 'jquery'
// 公共样式
export * from 'css/common/base.scss'
export * from 'css/index'
import { svgGradientGenerator } from 'css/assets/iconFont/gradient'
// 组件
export * from 'components/mask/wechat'
// 模块
import { lkArndTabModule } from 'modules/tab/lookaround'
// 基础UI组件
export * from 'css/components/base/aboutUs/index'
export * from 'css/components/base/ads/card/card1.scss'
export * from 'css/components/base/ads/note/note.scss'
export * from 'css/components/base/article/index.scss'
export * from 'css/components/base/banner/index.scss'
export * from 'css/components/base/mask/index.scss'
export * from 'css/components/base/message/index.scss'
export * from 'css/components/base/navBar/index.scss'
export * from 'css/components/base/progress/progress.scss'
export * from 'css/components/base/tag/index.scss'
export * from 'css/components/base/tile/index.scss'
//公共复合组件
export * from 'css/components/combined/search/search0/search0.scss'
export * from 'css/components/combined/card/game/index'
// 图标
export * from 'css/components/base/icon/index'
// 动画
export * from 'css/animation/marquee/index'
// 公共方法
import { dataBus, devType, resetFtSz } from 'js/common/index'
// 工具方法
import * as utils from 'utils'

function bindEvts () {
  createGlobalErrBus()
}

/**
 * 全局异常捕获
 */
function createGlobalErrBus () {
  window.onerror = function(msg, b) {
    utils.log.e(`[globalErrorBus]`, msg)
    return true
  }
}

/**
 * 数据总线初始化
 */
function dataBusInit () {
  // 浏览器 UA
  dataBus.set({
    key: 'browser',
    value: devType(),
  })

  // 实体映射集
  dataBus.set({
    key: 'entities',
    value: new Map(),
  })
}

/**
 * 模块初始化
 */
function modulesInit() {
  lkArndTabModule.init()
}

/**
 * ui 初始化
 */
function uiInit () {
  resetFtSz({
    orgFtSize: 40,
  })
  modulesInit()
  svgGradientGenerator()
}

$(document).ready(() => {
  // 判断设备类型
  dataBusInit()
  uiInit()
  bindEvts()
})
