// 常量
import * as constants from 'constants'
// 公共方法
import {
  tab,
} from 'js/common'
// 工具方法
import * as utils from 'utils'

/**
 * @param {Boolean} [optional] 可选。true 未找到元素不报错
 */
export function init({
  optional = true
} = {}) {
  let el = document.querySelector('.ym-tab-lkarnd')

  if (!el) {
    if (!optional) {
      error()
    }
    return
  }

  let tabs = el.querySelectorAll('.ym-tab-lkarnd-hd button')
  let blks = el.querySelectorAll('.ym-tab-lkarnd-bd ul')

  if (tabs.length === 0 || blks.length === 0) {
    return error()
  }

  uiInit({
    tabs,
    blks,
  })

  bindEvts({
    tabs,
    blks,
  })

  function error() {
    utils.log.e(constants.tips.console.domElNotFound)
    return false
  }
}

/**
 * 事件初始化
 */
function bindEvts({
  blks,
  tabs,
}) {
  tab.init({
    blks,
    tags: tabs,
  })
}

/**
 * UI 初始化
 */
function uiInit( {
  blks,
  tabs,
}) {
  tab.rmEmptyTab({
    blks,
    tabs,
  })
}